import Tag from 'app/types/Tag';
import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const apiClient = createApiClient(AppConstants.URL.TAGS);
const tagsApi = {
  tags: {
    createTag: (tagName: string) =>
      apiClient
        .post(``, {
          json: {
            tagName: tagName,
          },
        })
        .json<Tag>(),
    deleteTag: (tagName: string) => apiClient.delete(`${tagName}`).json(),
    getTagsByGroupkey: (group: string, id: string) =>
      apiClient.get(`group/${group}/${id}`).json<Tag[]>(),
    getTags: () => apiClient.get('').json<Tag[]>(),
  },
};
export default tagsApi;
