import { useAppSelector } from 'app/store';
import { selectLeadershipMembersByLeadershipId } from 'app/store/leadership/selectors';
import { selectPodMembersByPodId } from 'app/store/pods/selectors';
import { useLeadershipRoles, usePodRoles } from 'app/store/roles/selectors';
import { sortMembersWithRoles } from '../sortMembersWithRoles';

export const usePodMembers = (podId: string, staffOnly: boolean) => {
  const members = useAppSelector(selectPodMembersByPodId(podId));

  const roleList = usePodRoles();
  if (members === undefined) {
    return [];
  }
  const { flatList } = sortMembersWithRoles(members, roleList, staffOnly);
  return flatList;
};

export const useLeadershipMembers = (ltID: string, staffOnly: boolean) => {
  const members = useAppSelector(selectLeadershipMembersByLeadershipId(ltID));

  const roleList = useLeadershipRoles();
  if (members === undefined) {
    return [];
  }
  const { flatList } = sortMembersWithRoles(members, roleList, staffOnly);
  return flatList;
};
