import { ChevronDown } from '@cfa-icons/system';
import { useAppDispatch } from 'app/store';
import { setSelectedDeptTab } from 'app/store/app/slice';
import Department from 'app/types/Department';
import Role from 'app/types/Role';
import { Card, CardContent, Icon, Typography } from 'cfa-react-components';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../App.scss';
import './PointsOfContactCard.scss';

export type PointsOfContactCardProps = {
  rolesList: Role[];
  deptList: Department[];
  uniqueDepartments: string[];
  roleName: string;
  onProfile?: boolean;
};

export const PointsOfContactCard = ({
  rolesList,
  deptList,
  uniqueDepartments,
  roleName,
  onProfile,
}: PointsOfContactCardProps) => {
  rolesList?.sort((role1, role2) => role1.name.localeCompare(role2.name));
  const navigate = useNavigate();
  const [collapse, setCollapse] = useState(true);
  const dispatch = useAppDispatch();

  const handleClick = async (deptId: string) => {
    await dispatch(setSelectedDeptTab(deptId));
    navigate(`/pods`);
  };

  const handleCollapse = (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    event?.stopPropagation();
    setCollapse(!collapse);
  };

  return (
    <Card
      className="pointsOfContact-card"
      elevation={8}
      variant="default"
      onClick={e => handleCollapse(e)}
      data-cy="expand-lt"
    >
      <CardContent>
        <div className="name-arrow">
          <Typography
            className="pointsofcontact-role-name"
            color="secondary"
            variant="h4"
            align="left"
          >
            {roleName}
          </Typography>
          {onProfile != undefined ? (
            <Icon
              data-cy={`point-of-contact-dropdown-${roleName}`}
              style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                paddingLeft: '4px',
              }}
              icon={ChevronDown}
            ></Icon>
          ) : (
            <></>
          )}
        </div>
        <div
          className={
            onProfile && collapse ? 'card-collapsed' : 'card-uncollapsed'
          }
        >
          <div style={{ paddingBottom: '6px' }}></div>
          <div>
            {uniqueDepartments.map((deptId, index) => {
              const dept = deptList.find(
                department => department.id === deptId,
              );
              return dept ? (
                <Typography
                  className="pointsofcontact-dept-name"
                  variant="body1"
                  fontWeight="bold"
                  data-cy="point-of-contact-dept-name"
                  style={{
                    marginTop: '-10px',
                    marginBottom: '10px',
                    paddingTop: '6px',
                    alignSelf: 'flex-end',
                    marginRight: 'auto',
                  }}
                  onClick={() => handleClick(deptId)}
                >
                  {dept.name}
                </Typography>
              ) : null;
            })}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PointsOfContactCard;
