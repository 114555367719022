import RoleType from 'app/constants/ApiRole';
import Member from 'app/types/Member';
import Role from 'app/types/Role';

const defaultRole: Role = {
  id: '1',
  type: RoleType.DEFAULT_TYPE,
  name: 'Members',
  rank: '101',
  createdAt: new Date(),
};

export const sortMembersWithRoles = (
  members: Member[],
  rolesList: Role[],
  staffOnly: boolean,
) => {
  if (!members) {
    return { flatList: [], map: new Map<Role, Member[]>() };
  }
  let tempMap: Map<Role, Member[]> = new Map();

  members
    .toSorted((a, b) => a.name.localeCompare(b.name))
    .forEach(member => {
      if ((staffOnly && !member.isContingent) || !staffOnly) {
        if (!member.roleId) {
          addMemberToMap(tempMap, member, defaultRole);
        } else {
          let role =
            rolesList.find(role => role.id === member.roleId) ?? undefined;
          if (role) {
            addMemberToMap(tempMap, member, role);
          } else {
            addMemberToMap(tempMap, member, defaultRole);
          }
        }
      }
    });
  const sortedMap = new Map(
    Array.from(tempMap)
      .sort((firstRole, secondRole) => {
        return firstRole[0].name.localeCompare(secondRole[0].name); // Sort alphabetically
      })
      .sort((a, b) => {
        const rankA = Number(a[0].rank);
        const rankB = Number(b[0].rank);
        if (Number.isNaN(rankA) && !Number.isNaN(rankB)) {
          return 1;
        }
        if (!Number.isNaN(rankA) && Number.isNaN(rankB)) {
          return -1;
        }
        return rankA - rankB;
      }),
  );
  return { flatList: Array.from(sortedMap).flatMap(r => r[1]), map: sortedMap };
};

const addMemberToMap = (
  map: Map<Role, Member[]>,
  member: Member,
  role: Role,
) => {
  if (map.has(role)) {
    let tempArray = [...map.get(role)!];
    tempArray.push(member);
    map.set(role, tempArray);
  } else {
    map.set(role, [member]);
  }
};
