import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import MemberInfoSection from 'app/components/shared/CardRoleGroups/MemberInfoSection';
import { PictureDownloadButton } from 'app/components/shared/PictureDownloadButton/PictureDownloadButton';
import { useAppSelector } from 'app/store';
import { selectDepartmentById } from 'app/store/dept/selectors';
import { selectLeadershipById } from 'app/store/leadership/selectors';
import { EmployeeImageData } from 'app/types/ImageTypes';
import { allowEditLT } from 'app/utils/hasPermissions/allowFeature';
import { useLeadershipMembers } from 'app/utils/hooks/useMembers';
import {
  Breadcrumbs,
  Button,
  Divider,
  List,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';
import './Leadership.scss';

export const LeadershipProfilePage = () => {
  const navigate = useNavigate();
  const { leadershipId } = useParams() as { leadershipId: string };
  const [userImageData, setUserImageData] = useState<EmployeeImageData[]>([]);
  const leadership = useAppSelector(selectLeadershipById(leadershipId));
  const allowLeadershipEdit: boolean = allowEditLT();

  const leadershipMembers = useLeadershipMembers(leadershipId, false);

  const EditLeadership = () => {
    navigate(`/leadership-teams/${leadership?.id}/edit`);
  };

  const currentDept = useAppSelector(selectDepartmentById(leadership?.deptId!));

  return leadership ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <Breadcrumbs
            breadcrumbs={[
              {
                label: 'Leadership Teams',
                onClick: function backToLeadership() {
                  navigate('/leadership-teams');
                },
              },
              {
                label: leadership?.name,
              },
            ]}
          />
          {allowLeadershipEdit ? (
            <Button
              color="primary"
              onClick={EditLeadership}
              size="md"
              data-cy="edit-lt"
              variant="outlined"
              style={{
                justifySelf: 'end',
              }}
              disabled={!allowLeadershipEdit}
            >
              Edit Leadership Team
            </Button>
          ) : (
            <Tooltip content="Insufficient Permissions">
              <span>
                <Button
                  color="primary"
                  onClick={EditLeadership}
                  data-cy="edit-lt"
                  size="md"
                  variant="outlined"
                  style={{
                    justifySelf: 'end',
                  }}
                  disabled={!allowLeadershipEdit}
                >
                  Edit Leadership Team
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            {leadership.name}
          </Typography>
        </div>
        <div className="dept-section">
          <Typography
            variant="h3"
            color="secondary"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            <div style={{ color: '#80878C', fontSize: '22px' }}>
              {currentDept?.name!}
            </div>
          </Typography>
        </div>
        <div>
          <Typography
            variant="body1"
            color="secondary"
            fontWeight="medium"
            align="center"
            gutterBottom
            data-cy="pod-info"
          >
            {leadership.info}
          </Typography>
        </div>
        <div
          style={{
            width: '800px',
            margin: '0 auto',
            marginTop: '20px',
          }}
        >
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <List
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gridTemplateColumns: 'repeat(4, 1fr)',
              justifyContent: 'center',
              alignItems: 'stretch',
            }}
          >
            {leadershipMembers?.map((member, index) => (
              <MemberInfoSection
                member={member}
                index={index}
                setUserImageData={setUserImageData}
              />
            ))}
          </List>
          <Divider
            variant="fullLength"
            orientation="horizontal"
            style={{ marginBottom: '10px' }}
          ></Divider>
          <PictureDownloadButton
            userImageData={userImageData}
            staffOnly={false}
            name={leadership.name}
          />
        </div>
      </div>
      <Footer />
    </>
  ) : (
    <PageNotFound></PageNotFound>
  );
};
