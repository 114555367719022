import CreateLeaderRole from 'app/components/shared/FormDialog/CreateLTRole';
import { useAppSelector } from 'app/store';
import { selectDepartments } from 'app/store/dept/selectors';
import {
  useLeadershipRoles,
  usePodRoles,
  usePointOfContactRoles,
} from 'app/store/roles/selectors';
import Role from 'app/types/Role';
import {
  Card,
  CardContent,
  Divider,
  TabItem,
  TabPanel,
  Tabs,
  Typography,
} from 'cfa-react-components';
import React, { PropsWithChildren, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Navigation from '../../components/Navigation/Navigation';
import { CreateDept } from '../../components/shared/FormDialog/CreateDept';
import { CreatePointOfContactRole } from '../../components/shared/FormDialog/CreatePointOfContactRole';
import { CreateRole } from '../../components/shared/FormDialog/CreateRole';
import { EditDept } from '../../components/shared/FormDialog/EditDept';
import { EditRole } from '../../components/shared/FormDialog/EditRole';
import './Admin.scss';

function sortRoles(roles: Role[]) {
  return roles.sort((a, b) => {
    const rankA = Number(a.rank);
    const rankB = Number(b.rank);
    //console.log(rankA)
    if (Number.isNaN(rankA) && !Number.isNaN(rankB)) {
      return 1;
    }
    if (!Number.isNaN(rankA) && Number.isNaN(rankB)) {
      return -1;
    }
    return rankA - rankB;
  });
}

export const Admin = () => {
  const [currPage, setCurrPage] = useState('Role');
  const deptList = useAppSelector(selectDepartments);
  const podRoles = usePodRoles();
  const pointOfContactRoles = usePointOfContactRoles();
  const leadershipRoles = useLeadershipRoles();

  return (
    <div>
      <Navigation />
      <div className="main_container" data-cy="main-container">
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
            style={{ marginTop: '30px' }}
          >
            Admin
          </Typography>
        </div>
        <React.Fragment key=".0">
          <div className="nav_section">
            <Tabs activeItemKey={currPage} onChange={e => setCurrPage(e)}>
              <TabItem data-cy="roles-tab" itemKey="Role">
                View/Edit Pod Roles
              </TabItem>
              <TabItem data-cy="dept-tab" itemKey="Subdept">
                View/Edit Sub-Departments
              </TabItem>
              <TabItem data-cy="point-of-contact-tab" itemKey="PointOfContact">
                View/Edit Point of Contact Roles
              </TabItem>
              <TabItem data-cy="leadership-tab" itemKey="LeadershipTeams">
                View/Edit Leadership Teams Roles
              </TabItem>
            </Tabs>
          </div>
          <TabPanel itemKey="Role" activeItemKey={currPage}>
            <AdminRoleTab
              title="POD ROLES"
              roles={sortRoles(podRoles)}
              keyPrefix="pod"
            >
              <CreateRole />
            </AdminRoleTab>
          </TabPanel>
          <TabPanel itemKey="Subdept" activeItemKey={currPage}>
            <React.Fragment key=".0">
              <Card className="main_card" elevation={8} style={{ width: 488 }}>
                <CardContent style={{ maxWidth: 488 }}>
                  <Typography
                    data-cy="dept"
                    className="role_header"
                    variant="h2"
                    align="center"
                  >
                    SUB-DEPARTMENTS
                  </Typography>
                  <Divider style={{ width: 488, marginInlineStart: -16 }} />
                </CardContent>
                <CardContent
                  className="roles_list"
                  style={{ height: 445, maxHeight: 445, width: 448 }}
                >
                  {deptList &&
                    deptList.map(subdept => (
                      <React.Fragment key={`subdept-${subdept.id}`}>
                        <Typography
                          className="subdept_list_items"
                          variant="body1"
                          color="secondary"
                          style={{ padding: 10 }}
                        >
                          {subdept.name}
                          <EditDept
                            dept={subdept}
                            subDeptName={subdept.name}
                            subdeptID={subdept.id}
                          />
                        </Typography>
                        <Divider variant="fullLength" />
                      </React.Fragment>
                    ))}
                </CardContent>
                <CreateDept />
              </Card>
            </React.Fragment>
          </TabPanel>
          <TabPanel itemKey="PointOfContact" activeItemKey={currPage}>
            <AdminRoleTab
              title="POINT OF CONTACT ROLES"
              roles={sortRoles(pointOfContactRoles)}
              keyPrefix="pc"
            >
              <CreatePointOfContactRole />
            </AdminRoleTab>
          </TabPanel>
          <TabPanel itemKey="LeadershipTeams" activeItemKey={currPage}>
            <AdminRoleTab
              title="LEADERSHIP TEAMS"
              roles={sortRoles(leadershipRoles)}
              keyPrefix="lt"
            >
              <CreateLeaderRole />
            </AdminRoleTab>
          </TabPanel>
        </React.Fragment>
        <Footer />
      </div>
    </div>
  );
};

const AdminRoleTab = ({
  title,
  roles,
  children,
  keyPrefix,
}: {
  title: string;
  roles: Role[];
  keyPrefix?: string;
} & PropsWithChildren<{}>) => {
  return (
    <React.Fragment key=".0">
      <Card className="main_card" elevation={8} style={{ width: 488 }}>
        <CardContent style={{ maxWidth: 488 }}>
          <Typography
            data-cy="admin-role-section"
            className="role_header"
            variant="h2"
            align="center"
          >
            {title}
          </Typography>
          <Divider style={{ width: 488, marginInlineStart: -16 }} />
        </CardContent>
        <CardContent
          className="roles_list"
          style={{ height: 445, maxHeight: 445, width: 448 }}
        >
          <div style={{ display: 'flex', gap: '168px' }}>
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{ paddingLeft: '10px' }}
            >
              Role{' '}
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Rank
            </Typography>
          </div>
          {roles.map(role => (
            <>
              <div
                className="role_list_items"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 10,
                  width: '100%',
                }}
                key={`role-${role.id}`}
              >
                <Typography
                  className="role_list_items"
                  variant="body1"
                  color="secondary"
                  data-cy={`${keyPrefix}-role-name`}
                  style={{
                    flex: '1.1',
                  }}
                >
                  {role.name}
                </Typography>
                <Typography
                  className="role_list_items"
                  variant="body1"
                  color="secondary"
                  data-cy={`${keyPrefix}-role-rank`}
                  style={{ flex: '.85' }}
                >
                  {role.rank || 100}
                </Typography>
                <EditRole role={role} />
              </div>
              <Divider variant="fullLength" />
            </>
          ))}
        </CardContent>
        {children}
      </Card>
    </React.Fragment>
  );
};

export default Admin;
