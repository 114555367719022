import Guild from 'app/types/Guild';
import { Card, CardContent, Typography } from 'cfa-react-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../App.scss';
import './GuildsCard.scss';

export type GuildCardProps = {
  guild: Guild;
};

export const GuildCard = ({ guild }: GuildCardProps) => {
  const navigate = useNavigate();

  const handleClick = (guildID: string) => {
    navigate(`/guilds/${guildID}`);
  };

  return (
    <>
      <Card
        className="guild-card"
        elevation={8}
        variant="default"
        onClick={() => handleClick(guild.id)}
      >
        <React.Fragment key=".0">
          <CardContent className="card-content">
            <Typography
              className="guild-name"
              color="secondary"
              variant="h4"
              data-cy="guild-name"
            >
              {guild.name}
            </Typography>
            <Typography
              className="guild-desc"
              variant="body1"
              data-cy="guild-desc"
            >
              {guild.info}
            </Typography>
          </CardContent>
        </React.Fragment>
      </Card>
    </>
  );
};

export default GuildCard;
